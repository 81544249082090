import React from 'react';
import { Helmet } from 'react-helmet';
import Layout from '../components/Layout';
import Seo from '../components/Seo';

const LegalNotice = props => {

    const lang = props.pageContext.langKey;

    return (
        <Layout lang={lang} location={props.location}>
            <Seo title='Privacy Policy | Valudio' meta='Our privacy policy' slug='privacy-policy' />
            <Helmet>
                <meta property="robots" content="noindex, nofollow" />
            </Helmet>
            <div className="legal">
                <div className="container">
                    <h1 className="legal__heading">
                        Privacy Policy
                    </h1>
                    <div className="legal__section">
                        <p>
                            In line with the principles of legality, fairness and transparency, the Privacy Policy is provided below.
                        </p>
                    </div>
                    <div className="legal__section">
                        <h2 className="legal__subheading">
                            Controller
                        </h2>
                        <p>
                            CONTROLLER: <br />
                            Valudio GmbH
                        </p>
                        <p>
                            UID/VAT: <br />
                            CHE-440.167.772
                        </p>
                        <p>
                            REGISTERED OFFICE: <br />
                            Hardturmstrasse 161, 8005 Zürich
                        </p>
                        <p>
                            CONTACT: <br />
                            Email, info@valudio.com <br />
                            Telephone, +41 44 688 01 44
                        </p>
                    </div>
                    <div className="legal__section">
                        <h2 className="legal__subheading">
                            The Purpose of Processing of Personal Data
                        </h2>
                        <p>
                            Depending on the category of data subjects involved, the personal information will be collected and handled by us for the following purposes:
                        </p>
                        <h3>
                            POTENTIAL CUSTOMERS
                        </h3>
                        <p>
                            To manage the potential contractual relationship, manage the submission of the requested information, provide offers for our services and/or products that may be of interest and/or manage their application.
                        </p>
                        <h3>
                            CUSTOMERS
                        </h3>
                        <p>
                        To manage the potential contractual relationship, manage the submission of the requested information, provide offers for our services and/or products that may be of interest and/or manage their application.
                        </p>
                        <h3>
                        APPLICANTS
                        </h3>
                        <p>
                        To manage our professional relationship that and provide the services you expect us to offer, such as finding a job and make your job application process easier. This includes sending your CV to potential employers (clients of our organization) for evaluation.
                        </p>
                        <p>
                        To enable you to submit your CV for general applications, as well as those made for specific jobs.
                        </p>
                        <p>
                        To answer your enquiries.
                        </p>
                        <p>
                        To direct-market products and services, advise you of news, events and job vacancies in which you are interested.
                        </p>
                        <h3>
                        DEFINITIONS:
                        </h3>
                        <ul>
                            <li>
                            CLIENT: Any legal or natural person who contacts us in order to fill a vacant position within its organization.
                            </li>
                            <li>
                            APPLICCANT: Any natural person who contacts us in order to find employment.
                            </li>
                        </ul>
                    </div>
                    <div className="legal__section">
                        <h2 className="legal__subheading">
                            The Legal Basis for Processing your Personal Information
                        </h2>
                        <p>
                        Depending on the category of data subjects involved, the legal basis for processing the personal information will be:
                        </p>
                        <h3>
                        POTENTIAL CUSTOMERS
                        </h3>
                        <p>
                        Art. 6.1.b GDPR: processing is necessary in order to take steps at the request of the data subject prior to entering into a contract; <br />
                        Art. 6.1.f GDPR: Legitimate interests pursued by the controller (to send requested information, answer enquiries…). <br />
                        Art. 6.1.a GDPR: data subject’s consent (to provide offers for our services and products…).
                        </p>
                        <h3>
                        CUSTOMERS
                        </h3>
                        <p>
                        Art. 6.1.b GDPR: the performance of a contract to which the data subject is party.
                        </p>
                        <h3>
                        APPLICANTS
                        </h3>
                        <p>
                        Art. 6.1.a GDPR: data subject’s consent.
                        </p>
                        <p>
                        The data requested is appropriate, pertinent and strictly necessary, and, in no circumstance, is the data subject obliged to provide such data, but, lack of such communication may affect the objective of the service or imply the inability of its execution.
                        </p>
                    </div>
                    <div className="legal__section">
                        <h2 className="legal__subheading">
                            How long we will hold your information
                        </h2>
                        <p>
                        Data shall be preserved over the minimum period to supply the service appropriately, as well as to answer any possible liabilities and other legal requirements.
                        </p>
                    </div>
                    <div className="legal__section">
                        <h2 className="legal__subheading">
                            How we share your Personal Information
                        </h2>
                        <p>
                        Valudio GmbH may disclose the personal information relating to “applicants” to the following third parties:
                        </p>
                        <ul>
                            <li>
                            To the necessary suppliers to provide our services.
                            </li>
                            <li>
                            To our clients or potential employers: we will share your personal information with those who offer job vacancies you may be interested in, or with those clients who are interested in your profile. These recipients will be subject to contractual confidentiality obligations.
                            </li>
                        </ul>
                        <p>
                        Furthermore, we inform you that certain data may be communicated to:
                        </p>
                        <ul>
                            <li>
                            Banks and financial institutions in charge of collecting the contracted services or purchased products.
                            </li>
                            <li>
                            Public administrations with competence in the sectors of activity of Valudio GmbH, whether the law requires it.
                            </li>
                        </ul>
                    </div>
                    <div className="legal__section">
                        <h2 className="legal__subheading">
                            Do we transfer personal information outside of the European Economic Area (E.E.A.)?
                        </h2>
                        <p>
                        Applicant’s information may be transferred to organisations outside the European Economic Area. However, we inform you that we will only transfer applicant’s information to countries outside of the E.E.A if the European Commission has established that such countries offer an adequate level of protection for the information or to those in which Valudio GmbH has implemented appropriate safeguards to ensure the privacy of the applicant's information. For this purpose, we normally use the standard clauses approved by the European Commission.
                        </p>
                    </div>
                    <div className="legal__section">
                        <h2 className="legal__subheading">
                            Applicable Rights When Providing Data
                        </h2>
                        <p>
                        The data protection rights applicable to the interested parties are as follows:
                        </p>
                        <ul>
                            <li>
                            The right to request access to personal data.
                            </li>
                            <li>
                            The right of rectification or erasure.
                            </li>
                            <li>
                            The right to object.
                            </li>
                            <li>
                            The right to restriction of processing.
                            </li>
                            <li>
                            The right of data portability.
                            </li>
                            <li>
                            The tight not to be subject to a decision based solely on automated processing.
                            </li>
                            <p>
                            Holders of the personal data provided may exercise the rights on personal data protection by sending a written notification to Valudio GmbH’s registered address or to the e-mail to such effects info@valudio.com, attaching, in either case, a photocopy of your DNI (Spanish identification document) or other similar identification documents.
                            </p>
                            <p>
                            Models, application forms and other information regarding rights is available in the website www.agpd.es of the Control Authority, the Spanish Data Protection Agency, hereinafter, AEPD for its abbreviation in Spanish.
                            </p>
                        </ul>
                    </div>
                    <div className="legal__section">
                        <h2 className="legal__subheading">
                        Withdrawal of Consent
                        </h2>
                        <p>
                        You will have the possibility and right to withdraw authorization for any specific purpose granted at any given time without prejudice of the legality of processing according to the consent given before withdrawal.
                        </p>
                    </div>
                    <div className="legal__section">
                        <h2 className="legal__subheading">
                        Where Claims are Presented When There is Reason to Believe Data is not Being Correctly Processed
                        </h2>
                        <p>
                        If a party considers that Valudio GmbH is not correctly processing, any claims can be sent to info@valudio.com or to the corresponding data protection authority, this being AEPD in Spain, www.agpd.es
                        </p>
                    </div>
                    <div className="legal__section">
                        <h2 className="legal__subheading">
                        Security and Update of Personal Data
                        </h2>
                        <p>
                        In aims to preserve the security of your personal data, Valudio GmbH has implemented all the technical and organization related measures to guarantee safety for the provided data. This is done to prevent its alteration, loss, and/or unauthorized processing or access, as required by regulation, in the case of lack of total security.
                        </p>
                        <p>
                        In order to keep the data updated, it is essential for you to notify of any modifications.
                        </p>
                    </div>
                    <div className="legal__section">
                        <h2 className="legal__subheading">
                        Confidentiality
                        </h2>
                        <p>
                        All staff members of Valudio GmbH participating in any of the processing stages shall process and handle your data under strict care and confidentiality. Your data shall not be disclosed or communicated to a third party, unless required by legal provisions or the data subject has authorized otherwise.
                        </p>
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default LegalNotice;